import { INVENTORY_MANAGER_DATA_ATTRIBUTE_IDS as DataIds } from './data_attributes/inventory_manager'

export const INVENTORY_FORECAST_COLUMNS = [
  {
    displayName: 'Preview',
    value: 'amazon_image_url',
    name: 'amazon_image_url',
    selected: true
  },
  {
    displayName: 'ASIN',
    value: 'asin',
    name: 'asin',
    selected: false
  },
  {
    displayName: 'SKU',
    value: 'sku',
    name: 'sku',
    selected: true
  },
  {
    displayName: 'Product Title',
    value: 'title',
    name: 'title',
    selected: true
  },
  {
    displayName: 'Inventory Status',
    value: 'inventory_status',
    name: 'inventory_status',
    selected: true
  },
  {
    displayName: 'Date to Reorder',
    value: 'date_to_reorder',
    name: 'date_to_reorder',
    selected: true
  },
  {
    displayName: 'Reorder Quantity',
    value: 'reorder_qty',
    name: 'reorder_qty',
    selected: true
  },
  {
    displayName: 'Reorder Cost',
    value: 'reorder_cost',
    name: 'reorder_cost',
    selected: true
  },
  {
    displayName: 'Reorder Profit',
    value: 'reorder_profit',
    name: 'reorder_profit',
    selected: false
  },
  {
    displayName: 'Reorder Days of Supply',
    value: 'reorder_days_of_supply',
    name: 'reorder_days_of_supply',
    selected: true
  },
  {
    displayName: 'Lead Time',
    value: 'lead_time',
    name: 'lead_time',
    selected: false
  },
  {
    displayName: 'Days Remaining',
    value: 'days_remaining',
    name: 'days_remaining',
    selected: false
  },
  {
    displayName: 'Inventory Level',
    value: 'inventory_level',
    name: 'inventory_level',
    selected: true
  },
  {
    displayName: 'Inbound Level',
    value: 'inbound_level',
    name: 'inbound_level',
    selected: false
  },
  {
    displayName: 'Inventory Level Revenue',
    value: 'inventory_level_revenue',
    name: 'inventory_level_revenue',
    selected: false
  },
  {
    displayName: 'Inventory Level Cost',
    value: 'inventory_level_cost',
    name: 'inventory_level_cost',
    selected: false
  },
  {
    displayName: 'Inventory Level Profit',
    value: 'inventory_level_profit',
    name: 'inventory_level_profit',
    selected: false
  },
  {
    displayName: 'Average Daily Sales',
    value: 'average_daily_sales',
    name: 'average_daily_sales',
    selected: false
  },
  // {
  //   displayName: 'Supplier',
  //   value: 'supplier_name',
  //   name: 'supplier_name',
  //   selected: false
  // },
  {
    displayName: 'Average Profit per Unit',
    value: 'avg_profit_per_unit',
    name: 'avg_profit_per_unit',
    selected: false
  }
]

export const SALES_HISTORY_OPTIONS = [
  {
    name: '15 Days',
    value: 15,
    dataId: DataIds.Filters.HistoryDropdown.FifteenDays
  },
  {
    name: '30 Days',
    value: 30,
    dataId: DataIds.Filters.HistoryDropdown.ThirthyDays
  },
  {
    name: '60 Days',
    value: 60,
    dataId: DataIds.Filters.HistoryDropdown.SixtyDays
  },
  {
    name: '90 Days',
    value: 90,
    dataId: DataIds.Filters.HistoryDropdown.NinetyDays
  },
  {
    name: '120 Days',
    value: 120,
    dataId: DataIds.Filters.HistoryDropdown.HundredAndTwentyDays
  }
]

const DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS = [
  {
    displayName: 'Overstocked',
    name: 'excess',
    value: 'excess',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.Overstocked
  },
  {
    displayName: 'In Stock',
    name: 'in_stock',
    value: 'in_stock',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.InStock
  },
  {
    displayName: 'Reorder Soon',
    name: 'reorder_soon',
    value: 'reorder_soon',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.ReorderSoon
  },
  {
    displayName: 'Order Now',
    name: 'order_now',
    value: 'order_now',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.OrderNow
  }
]

export const INVENTORY_STATUS_FILTER_OPTIONS = [
  ...DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS,
  {
    displayName: 'No Status',
    name: 'no_status',
    value: 'no_status',
    selected: true,
    dataId: DataIds.Filters.InventoryStatusDropdown.NoStatus
  }
]
export const INVENTORY_FORECAST_SELECTED_COLUMNS =
  'INVENTORY_FORECAST_SELECTED_COLUMNS'

export const FILTERS_INITIAL_STATE = {
  averageDailySalesMax: '',
  averageDailySalesMin: '',
  dateToReorderMaxObject: null,
  dateToReorderMinObject: null,
  daysRemainingMax: '',
  daysRemainingMin: '',
  inboundLevelMax: '',
  inboundLevelMin: '',
  inventoryLevelMax: '',
  inventoryLevelMin: '',
  reorderQuantityMax: '',
  reorderQuantityMin: '',
  selectedStatus: DEFAULT_INVENTORY_STATUS_FILTER_OPTIONS
  // suppliers: []
}

export const SPLIT_PANEL_TEXT = t => {
  return {
    daysLabelText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.daysRemainingLabel',
      'Days Remaining'
    ),
    daysPlaceholderText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.daysRemainingPlaceholder',
      'Days'
    ),
    reorderLabelText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.reorderQtyLabel',
      'Reorder Quantity'
    ),
    reorderPlaceholderText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.reorderQtyPlaceholder',
      'Qty'
    ),
    inventoryLabelText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.inventoryLevelLabel',
      'Inventory Level'
    ),
    inventoryPlaceholderText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.inventoryLevelPlaceholder',
      'Inventory'
    ),
    inboundLevelLabelText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.inboundLevelLabel',
      'Inbound Level'
    ),
    inboundLevelPlaceholderText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.inboundLevelPlaceholder',
      'Level'
    ),
    avgDailySalesLabelText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.averageDailySalesLabel',
      'Average Daily Sales'
    ),
    avgDailySalesPlaceholderText: t(
      'inventory:InventoryForecastSearchFilter.splitPanels.averageDailySalesPlaceholder',
      'Sales'
    )
  }
}

export const FORM_ATTRIBUTES = t => ({
  reorder_days_of_supply: t(
    'product_costs_and_settings:Table.reorderDaysOfSupply',
    'Reorder Days of Supply'
  ),
  lead_time_in_days: t(
    'product_costs_and_settings:Table.leadTime',
    'Lead Time'
  ),
  date_effective: t(
    'product_costs_and_settings:costModal.FieldGroup.effectiveDate',
    'Effective Date'
  ),
  misc_cost: t(
    'product_costs_and_settings:costModal.FieldGroup.miscellaneousCost',
    'Miscellaneous Cost Per Unit'
  ),
  shipping_cost: t(
    'product_costs_and_settings:costModal.FieldGroup.supplierCost',
    'Supplier Shipping Cost Per Unit'
  ),
  unit_cost: t(
    'inventory:InventoryForecastSearchFilter.FieldGroup.goodsCost',
    'Cost of Goods Sold Per Unit'
  )
})
