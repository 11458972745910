export const DEFAULT_PLAN_FEATURE_LIMITS = {
  suite: {
    name: 'Suite',
    productTrackerLimit: '150',
    productDatabaseSearches: 'Unlimited',
    keywordScoutSearches: 'Unlimited',
    opportunityFinderSearches: 'Unlimited',
    historicalProductTrackerData: '3 Month',
    historicalKeywordScoutData: '1 Year',
    listingBuilder: 'Full Access',
    aiAssist: '50 / Month',
    aiAssistChat: 'Advanced',
    rankTracker: '500'
  },
  growthAccelerator: {
    name: 'Growth Accelerator',
    productTrackerLimit: '150',
    productDatabaseSearches: 'Unlimited',
    keywordScoutSearches: '250 / Month',
    opportunityFinderSearches: 'Unlimited',
    historicalProductTrackerData: '3 Month',
    historicalKeywordScoutData: '1 Year',
    listingBuilder: 'Full Access',
    aiAssist: '100 / Month',
    aiAssistChat: 'Advanced',
    rankTracker: '500'
  },
  pro: {
    name: 'Pro',
    productTrackerLimit: '1000',
    productDatabaseSearches: 'Unlimited',
    keywordScoutSearches: 'Unlimited',
    opportunityFinderSearches: 'Unlimited',
    historicalProductTrackerData: '6 Month',
    historicalKeywordScoutData: '2 Year',
    listingBuilder: 'Full Access',
    aiAssist: '300 / Month',
    aiAssistChat: 'Advanced',
    rankTracker: '5000'
  },
  brandOwner: {
    name: 'Brand Owner',
    productTrackerLimit: '2000',
    productDatabaseSearches: 'Unlimited',
    keywordScoutSearches: 'Unlimited',
    opportunityFinderSearches: 'Unlimited',
    historicalProductTrackerData: '6 Month',
    historicalKeywordScoutData: '2 Year',
    listingBuilder: 'Full Access',
    aiAssist: '500 / Month',
    aiAssistChat: 'Advanced',
    rankTracker: '5000'
  }
}
